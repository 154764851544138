@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?50230966');
  src: url('../font/fontello.eot?50230966#iefix') format('embedded-opentype'),
    url('../font/fontello.woff2?50230966') format('woff2'),
    url('../font/fontello.woff?50230966') format('woff'),
    url('../font/fontello.ttf?50230966') format('truetype'),
    url('../font/fontello.svg?50230966#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?50230966#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-plus:before {
  content: '\e800';
} /* '' */
.icon-email:before {
  content: '\e801';
} /* '' */
.icon-profile:before {
  content: '\e802';
} /* '' */
.icon-flèche-droite:before {
  content: '\e803';
} /* '' */
.icon-recherche:before {
  content: '\e804';
} /* '' */
.icon-facebook:before {
  content: '\e805';
} /* '' */
.icon-contact:before {
  content: '\e806';
} /* '' */
.icon-profil:before {
  content: '\e807';
} /* '' */
.icon-like-copy-4:before {
  content: '\e808';
} /* '' */
.icon-like-copy-3:before {
  content: '\e809';
} /* '' */
.icon-delete:before {
  content: '\e80a';
} /* '' */
.icon-suivi_active:before {
  content: '\e80b';
} /* '' */
.icon-check:before {
  content: '\e80c';
} /* '' */
.icon-back:before {
  content: '\e80d';
} /* '' */
.icon-cross:before {
  content: '\e80e';
} /* '' */
.icon-profile_active:before {
  content: '\e80f';
} /* '' */
.icon-pushn:before {
  content: '\e810';
} /* '' */
.icon-recherche_active:before {
  content: '\e811';
} /* '' */
.icon-phone:before {
  content: '\e812';
} /* '' */
.icon-search_chambre_active:before {
  content: '\e813';
} /* '' */
.icon-suivi:before {
  content: '\e814';
} /* '' */
.icon-search_combien:before {
  content: '\e815';
} /* '' */
.icon-minus:before {
  content: '\e816';
} /* '' */
.icon-star:before {
  content: '\e817';
} /* '' */
.icon-search-2:before {
  content: '\e818';
} /* '' */
.icon-google:before {
  content: '\e819';
} /* '' */
.icon-search_chambres:before {
  content: '\e81a';
} /* '' */
.icon-edit:before {
  content: '\e81b';
} /* '' */
.icon-search_envies:before {
  content: '\e81c';
} /* '' */
.icon-tb_fav_active:before {
  content: '\e81d';
} /* '' */
.icon-search_ou:before {
  content: '\e81e';
} /* '' */
.icon-search_pieces_active:before {
  content: '\e81f';
} /* '' */
.icon-search_pieces:before {
  content: '\e820';
} /* '' */
.icon-search_surface_active:before {
  content: '\e821';
} /* '' */
.icon-search_surface:before {
  content: '\e822';
} /* '' */
.icon-search_type_active:before {
  content: '\e823';
} /* '' */
.icon-search_combien_active:before {
  content: '\e824';
} /* '' */
.icon-search_type:before {
  content: '\e825';
} /* '' */
.icon-search_envies_active:before {
  content: '\e826';
} /* '' */
.icon-search_exclure_active:before {
  content: '\e827';
} /* '' */
.icon-search_ou_active:before {
  content: '\e828';
} /* '' */
.icon-reception_active:before {
  content: '\e829';
} /* '' */
.icon-angle-down-1:before {
  content: '\e82a';
} /* '' */
.icon-angle-up-1:before {
  content: '\e82b';
} /* '' */
.icon-chrono:before {
  content: '\e82c';
} /* '' */
.icon-list:before {
  content: '\e82d';
} /* '' */
.icon-plus-1:before {
  content: '\e82e';
} /* '' */
.icon-messenger:before {
  content: '\e82f';
} /* '' */
.icon-whatsapp:before {
  content: '\e830';
} /* '' */
.icon-share:before {
  content: '\e831';
} /* '' */
.icon-reception:before {
  content: '\e832';
} /* '' */
.icon-camera:before {
  content: '\e833';
} /* '' */
.icon-daily:before {
  content: '\e834';
} /* '' */
.icon-real_time:before {
  content: '\e835';
} /* '' */
.icon-demasquer:before {
  content: '\e836';
} /* '' */
.icon-hide:before {
  content: '\e837';
} /* '' */
.icon-hebdo:before {
  content: '\e838';
} /* '' */
.icon-plus_active:before {
  content: '\e839';
} /* '' */
.icon-reset:before {
  content: '\e83a';
} /* '' */
.icon-search_exclure:before {
  content: '\e83b';
} /* '' */
.icon-cgu:before {
  content: '\e83c';
} /* '' */
.icon-tb_annonces_active:before {
  content: '\e83d';
} /* '' */
.icon-tb_annonces:before {
  content: '\e83e';
} /* '' */
.icon-tb_compte_active:before {
  content: '\e83f';
} /* '' */
.icon-search_sdb_active:before {
  content: '\e840';
} /* '' */
.icon-search_sdb:before {
  content: '\e841';
} /* '' */
.icon-display_card_active:before {
  content: '\e842';
} /* '' */
.icon-display_list_active:before {
  content: '\e843';
} /* '' */
.icon-tb_fav:before {
  content: '\e844';
} /* '' */
.icon-am_commodites:before {
  content: '\e845';
} /* '' */
.icon-link:before {
  content: '\e846';
} /* '' */
.icon-display_card:before {
  content: '\e847';
} /* '' */
.icon-display_list:before {
  content: '\e848';
} /* '' */
.icon-more:before {
  content: '\e849';
} /* '' */
.icon-show:before {
  content: '\e84a';
} /* '' */
.icon-tb_paper:before {
  content: '\e84b';
} /* '' */
.icon-settings:before {
  content: '\e84c';
} /* '' */
.icon-dislike:before {
  content: '\e84d';
} /* '' */
.icon-tb_paper_active:before {
  content: '\e84e';
} /* '' */
.icon-logout:before {
  content: '\e84f';
} /* '' */
.icon-tag:before {
  content: '\e850';
} /* '' */
.icon-paper_settings:before {
  content: '\e851';
} /* '' */
.icon-paper_valider:before {
  content: '\e852';
} /* '' */
.icon-password:before {
  content: '\e853';
} /* '' */
.icon-maison:before {
  content: '\e854';
} /* '' */
.icon-parking:before {
  content: '\e855';
} /* '' */
.icon-building:before {
  content: '\e856';
} /* '' */
.icon-panneau:before {
  content: '\e857';
} /* '' */
.icon-am_manger:before {
  content: '\e858';
} /* '' */
.icon-am_parquet:before {
  content: '\e859';
} /* '' */
.icon-am_travaux2:before {
  content: '\e85a';
} /* '' */
.icon-chevron_right-1:before {
  content: '\e85c';
} /* '' */
.icon-filtre:before {
  content: '\e85d';
} /* '' */
.icon-am_parking:before {
  content: '\e85e';
} /* '' */
.icon-am_travaux:before {
  content: '\e864';
} /* '' */
.icon-am_balcon:before {
  content: '\e867';
} /* '' */
.icon-am_pieces:before {
  content: '\e86e';
} /* '' */
.icon-am_volets:before {
  content: '\e870';
} /* '' */
.icon-am_localisation:before {
  content: '\e872';
} /* '' */
.icon-am_toilettes:before {
  content: '\e874';
} /* '' */
.icon-am_buanderie:before {
  content: '\e875';
} /* '' */
.icon-am_tax2:before {
  content: '\e876';
} /* '' */
.icon-am_qualite_vie:before {
  content: '\e877';
} /* '' */
.icon-am_plain_pied:before {
  content: '\e878';
} /* '' */
.icon-am_piscine:before {
  content: '\e879';
} /* '' */
.icon-am_orientation:before {
  content: '\e87a';
} /* '' */
.icon-am_jardin:before {
  content: '\e87b';
} /* '' */
.icon-terrain:before {
  content: '\e87c';
} /* '' */
.icon-am_gardien3:before {
  content: '\e87d';
} /* '' */
.icon-am_duplex:before {
  content: '\e87e';
} /* '' */
.icon-am_douche:before {
  content: '\e87f';
} /* '' */
.icon-am_ascenseur:before {
  content: '\e880';
} /* '' */
.icon-am_patio:before {
  content: '\e881';
} /* '' */
.icon-am_agence:before {
  content: '\e882';
} /* '' */
.icon-am_pmr:before {
  content: '\e883';
} /* '' */
.icon-am_particulier:before {
  content: '\e884';
} /* '' */
.icon-am_clim:before {
  content: '\e885';
} /* '' */
.icon-am_chauffage:before {
  content: '\e886';
} /* '' */
.icon-am_cheminee:before {
  content: '\e887';
} /* '' */
.icon-am_dernier:before {
  content: '\e888';
} /* '' */
.icon-am_floor:before {
  content: '\e889';
} /* '' */
.icon-am_metro:before {
  content: '\e88a';
} /* '' */
.icon-am_meuble:before {
  content: '\e88b';
} /* '' */
.icon-am_sdb:before {
  content: '\e88c';
} /* '' */
.icon-am_sud:before {
  content: '\e88d';
} /* '' */
.icon-am_surface:before {
  content: '\e88e';
} /* '' */
.icon-am_cave:before {
  content: '\e88f';
} /* '' */
.icon-am_rdc:before {
  content: '\e890';
} /* '' */
.icon-am_chambres:before {
  content: '\e891';
} /* '' */
.icon-chevron_right:before {
  content: '\e893';
} /* '' */
.icon-reception2:before {
  content: '\e894';
} /* '' */
