@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-ExtraLight.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-ExtraLightItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-Light.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-LightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('assets/fonts/NunitoSans-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
