@import url('./fonts.css');
@import url('./assets/fontello/css/fontello.css');
/* @import url('./assets/fontello/css/fontello-embedded.css'); */
/* @import url('./assets/fontello/css/fontello-codes.css'); */
@import url('./assets/fontello/css/animation.css');

body {
  margin: 0;
  font-family: 'NunitoSans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background-color: white;
}

a {
  color: #1787e0;
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

header a,
footer a {
  color: #ffffff;
}

textarea,
select,
input,
button,
a {
  border: none;
}

textarea,
select,
input,
button,
a,
textarea:focus,
select:focus,
input:focus,
button:focus,
a:focus {
  outline: none !important;
}

.lock-body {
  overflow: hidden;
}

ul {
  list-style-type: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #1787e0;
  -webkit-text-fill-color: #114b62;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* ---------- CookieBot style ---------- */

#CookieImg {
  margin: 20px auto 0 auto !important;
  width: 150px !important;
  display: block !important;
}

#CybotCookiebotDialogBodyButtonsWrapper > button {
  border-radius: 50px !important;
}
